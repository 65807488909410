import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import Select, { createFilter } from "react-select";

import Header from "../../Components/common/Header";
import Draggable from "../sketch/Draggable";
import Loader from "../../Components/common/Loader";
import Timer from "../../Components/common/Timer";

import { ModeContext } from "../../context/ModeContext";

import { Checkbox, Button } from "antd";

import {
  getNextLabelVerification,
  updateLabelVerification,
  getAvgPrice,
  getImgUrls,
} from "../../API/LabelVerification";
import { ClearClaims } from "../../API/Logout";

import "./LabelVerfication.css";

const LabelVerification = () => {
  const initialImgData = {
    image_url: "",
    user: "",
    audit_id: "",
    store_name: "",
    client_name: "",
    image_index: 0,
    bbox_index: 0,
    date: "",
    bay_number: "",
    bay_type: "",
    finalized_classification_bbox: [],
    classification_labels: [],
    classification_scores: [],
    product_image_urls: [],
    labels: [],
  };

  const [imgData, setImgData] = useState(initialImgData);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [err, setErr] = useState(false);
  const [avgRegularPrice, setAvgRegularPrice] = useState([]);
  const [avgDiscountPrice, setAvgDiscountPrice] = useState([]);

  const [selectedLabel, setSelectedLabel] = useState("");
  const [options, setOptions] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);

  const role = localStorage.getItem("role");
  const [currentMode, setCurrentMode] = useContext(ModeContext);
  const isInitialMount = useRef(true);
  const [selectedImgUrls, setSelectedImgUrls] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    (async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        let res = await ClearClaims();
        getLabelVerification();
      }
    })();
  }, [currentMode]);

  const getLabelVerification = () => {
    setLoading(true);
    setAvgRegularPrice([]);
    setAvgDiscountPrice([]);
    const params = {};
    if (role === "expert" && currentMode === "ordinary") {
      params.qa_level = "ordinary";
    }
    getNextLabelVerification(params)
      .then((res) => {
        if (res.error) {
          setErr(true);
          alert(res.message);
          setImgData(initialImgData);
        } else {
          if (err) setErr(false);
          setImgData({ ...res.data });
          setSeconds(600);
        }
        setLoading(false);
        res.data.classification_labels.map((label, key) => {
          getAvgPrice(label).then((response) => {
            if (!response.error) {
              setAvgRegularPrice((pre) => {
                pre[key] = response.data.avg_base_price || 0;
                return [...pre];
              });
              setAvgDiscountPrice((pre) => {
                pre[key] = response.data.avg_disconut_price || 0;
                return [...pre];
              });
            }
          });
        });
        if (role === "expert") {
          let newOptionsArray = [];
          res.data.labels.forEach((label) => {
            newOptionsArray.push({ label: label, value: label });
          });
          setOptions([...newOptionsArray]);
          setSearchOptions([...newOptionsArray]);
        }
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    getLabelVerification();
    console.log("component rendered");
    return () => ClearClaims();
  }, []);

  const timerCallback = () => {
    setSeconds(0);
    getLabelVerification();
  };

  const sendData = (label, image_url) => {
    let resBody = {
      image_url: imgData.image_url,
      user: imgData.user,
      audit_id: imgData.audit_id,
      store_name: imgData.store_name,
      client_name: imgData.client_name,
      image_index: imgData.image_index,
      bbox_index: imgData.bbox_index,
      date: imgData.date,
      bay_number: imgData.bay_number,
      bay_type: imgData.bay_type,
    };
    resBody.finalized_classification_label = label;
    resBody.product_image_url = image_url;
    if (role === "expert" && currentMode === "ordinary") {
      resBody.qa_level = currentMode;
    }
    setLoading(true);
    setChecked(false);
    updateLabelVerification(resBody).then((res) => {
      if (res.error) {
        alert(res.message);
      }
      setSeconds(0);
      getLabelVerification();
    });
  };

  const done = () => {
    if (selectedLabel !== "") {
      let resBody = {
        image_url: imgData.image_url,
        user: imgData.user,
        audit_id: imgData.audit_id,
        store_name: imgData.store_name,
        client_name: imgData.client_name,
        image_index: imgData.image_index,
        bbox_index: imgData.bbox_index,
        date: imgData.date,
        bay_number: imgData.bay_number,
        bay_type: imgData.bay_type,
        add_product_images: checked,
      };
      resBody.finalized_classification_label = selectedLabel.label;
      resBody.product_image_url = "";
      if (role === "expert" && currentMode === "ordinary") {
        resBody.qa_level = currentMode;
      }
      updateLabelVerification(resBody).then((res) => {
        if (res.error) {
          alert(res.message);
        }
        setSeconds(0);
        getLabelVerification();
        setSelectedLabel("");
        setChecked(false);
      });
    } else {
      alert("please select appropriate label from dropdown");
    }
  };

  const skip = () => {
    let role = localStorage.getItem("role");
    let resBody = {
      image_url: imgData.image_url,
      user: imgData.user,
      audit_id: imgData.audit_id,
      store_name: imgData.store_name,
      client_name: imgData.client_name,
      image_index: imgData.image_index,
      bbox_index: imgData.bbox_index,
      date: imgData.date,
      bay_number: imgData.bay_number,
      bay_type: imgData.bay_type,
      product_image_url: "",
    };
    if (role === "ordinary") {
      resBody.escalate = true;
    } else {
      resBody.skip = true;
      resBody.qa_level = currentMode;
    }
    setLoading(true);
    updateLabelVerification(resBody).then((res) => {
      if (res.error) {
        alert(res.message);
      }
      setSeconds(0);
      getLabelVerification();
    });
  };

  const handleChange = useCallback(
    (selectedOption) => {
      setSelectedLabel(selectedOption);
      getImgUrls({ product_id: selectedOption.value })
        .then((res) => {
          console.log(res);
          if (!res.error) {
            setSelectedImgUrls(res.data);
          } else {
            alert(res.message);
            setSelectedImgUrls([]);
          }
        })
        .catch((err) => {
          alert(err.message);
          setSelectedImgUrls([]);
        });
    },
    [setSelectedLabel]
  );

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "any",
  };

  const filterOptions = (candidate, input) => {
    if (input) {
      return candidate.value.includes(input);
    }
    return true;
  };

  function arrayUnique(arr, uniqueKey) {
    const flagList = [];
    return arr.filter(function(item) {
      if (flagList.indexOf(item[uniqueKey]) === -1) {
        flagList.push(item[uniqueKey]);
        return true;
      }
    });
  }

  const onInputChange = (input) => {
    setLoading(true);
    var arrData = [];
    for (var i = 0; i < searchOptions.length; i++) {
      //console.log(input.toLowerCase().replaceAll(/ /g, ""));
      //console.log(options[i].value.toLowerCase().replaceAll("_", ""));
      var text_1 = searchOptions[i].label.toLowerCase().replaceAll("_", "");
      var text_2 = input.toLowerCase().replaceAll(/ /g, "");
      var arrInput = input
        .toLowerCase()
        .replaceAll("_", "")
        .split(" ");
      var found = false;
      var count = 0;
      for (var j = 0; j < arrInput.length; j++) {
        if (text_1.includes(arrInput[j])) {
          found = true;
          count++;
        } else found = false;
      }
      if (found === true && count === arrInput.length)
        arrData.push({
          label: searchOptions[i].label,
          value: searchOptions[i].value,
        });
      //}
    }
    arrData = arrayUnique(arrData, "value");
    setOptions(arrData);
    setLoading(false);
  };

  const onCheckImage = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <div>
      {loading && <Loader />}
      <Header />
      <div style={{ padding: "20px" }} className="user-details">
        <p>
          <strong>Added By :</strong> {imgData.user}
        </p>
        <p>
          <strong>Audit ID :</strong> {imgData.audit_id}
        </p>
        <p>
          <strong>Store Name:</strong> {imgData.store_name}
        </p>
        <p>
          <strong>Manufacturer Name:</strong> {imgData.client_name}
        </p>
        <p>
          <strong>Image Index:</strong> {imgData.image_index}
        </p>
        <p>
          <strong>Bbox Index:</strong> {imgData.bbox_index}
        </p>
        <p>
          <strong>Date Added:</strong> {imgData.date}
        </p>
        <p>
          <strong>Bay number:</strong> {imgData.bay_number}
        </p>
        <p>
          <strong>Bay type:</strong> {imgData.bay_type}
        </p>
        <p>
          <strong>Total Pending Images :</strong> {imgData.pending || 0}
        </p>
        {seconds > 0 && (
          <p>
            <strong>Time Remaining:</strong>{" "}
            <Timer seconds={seconds} callback={timerCallback} />
          </p>
        )}
      </div>

      <div className="main-container">
        <div className="partition1">
          {!err && (
            <div>
              <div className="image-container">
                {imgData.image_url.length > 0 && (
                  <Draggable
                    img={imgData.image_url}
                    data={[
                      imgData.finalized_classification_bbox[1],
                      imgData.finalized_classification_bbox[0],
                      imgData.finalized_classification_bbox[3],
                      imgData.finalized_classification_bbox[2],
                    ]}
                    bbox_index={imgData.bbox_index}
                  />
                )}
              </div>
            </div>
          )}
          {!err && (
            <>
              <div className="options-container">
                {imgData.classification_labels.map((label, key) => {
                  return (
                    <div
                      className="card"
                      style={{ width: "18rem", position: "relative" }}
                      key={key}
                      onClick={() =>
                        sendData(label, imgData.product_image_urls[key])
                      }
                    >
                      <img
                        className="card-img-top"
                        src={
                          imgData.product_image_urls[key] ||
                          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                        }
                        alt="Card cap"
                      />
                      <div
                        className="card-body"
                        style={{ position: "relative", width: "100%" }}
                      >
                        <p
                          className="card-text"
                          style={{ width: "100%", overflow: "auto" }}
                        >
                          {label}
                        </p>
                        <p>AVG Regular Price: {avgRegularPrice[key]}</p>
                        <p>AVG Discount Price: {avgDiscountPrice[key]}</p>
                        <p></p>
                      </div>
                    </div>
                  );
                })}
                {role !== "expert" && (
                  <div
                    className="card"
                    style={{ width: "18rem" }}
                    onClick={skip}
                  >
                    <div className="card-body">
                      <h5 className="card-title">Not Sure</h5>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="partition2">
          {role === "expert" && (
            <div style={{ width: "25rem" }}>
              <Select
                options={options}
                styles={{
                  container: (provided, styles) => ({
                    ...provided,
                    marginBottom: "20px",
                    width: "100%",
                  }),
                }}
                value={selectedLabel}
                onChange={handleChange}
                //filterOption={filterOptions}
                filterOption={false}
                onInputChange={(x) => onInputChange(x)}
              />
              <Checkbox checked={checked} onChange={onCheckImage}>
                Add to Sample Images
              </Checkbox>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                  marginTop: 15,
                }}
              >
                <button
                  onClick={done}
                  style={{
                    border: "none",
                    padding: "10px 40px",
                    backgroundColor: "greenyellow",
                    color: "white",
                  }}
                >
                  Done
                </button>
                <button
                  onClick={skip}
                  style={{
                    border: "none",
                    padding: "10px 40px",
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  Skip
                </button>
              </div>
              <div className="sample-images">
                {selectedImgUrls.map((imgUrl, index) => {
                  return (
                    <img
                      style={{ maxWidth: "300px", maxHeight: "300px" }}
                      key={index}
                      src={imgUrl}
                      alt={"product_image"}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabelVerification;
