import React, { useEffect, useState, useContext, useRef } from "react";

import Main from "../sketch/Main";
import Rect from "../sketch/Rect";
import Header from "../../Components/common/Header";
import Loader from "../../Components/common/Loader";
import Timer from "../../Components/common/Timer";

import { ModeContext } from "../../context/ModeContext";

import { getNextBboxVerification, updateBboxMarking } from "../../API/Bbox";
import { ClearClaims } from "../../API/Logout";

const Bbox = () => {
  const initialImgData = {
    image_url: "",
    user: "",
    audit_id: "",
    store_name: "",
    client_name: "",
    image_index: 0,
    date: "",
    bay_number: "",
    bay_type: "",
    finalized_classification_bboxes: [],
  };

  const [imgData, setImgData] = useState({ ...initialImgData });
  const [shapes, setShapes] = useState([]);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [err, setErr] = useState(false);
  const role = localStorage.getItem("role");
  const [currentMode, setCurrentMode] = useContext(ModeContext);
  const isInitialMount = useRef(true);

  useEffect(() => {
    (async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        let res = await ClearClaims();
        getBboxVerification();
      }
    })();
  }, [currentMode]);

  const randomColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6) + "50";
  };

  const getBboxVerification = () => {
    // ClearClaims();
    setLoading(true);
    const params = {};
    if (role === "expert" && currentMode === "ordinary") {
      params.qa_level = "ordinary";
    }
    getNextBboxVerification(params)
      .then((res) => {
        if (res.error) {
          setErr(true);
          alert(res.message);
          setImgData(initialImgData);
        } else {
          if (err) setErr(false);
          setImgData({ ...res.data });
          setSeconds(600);
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    getBboxVerification();
    return () => ClearClaims();
  }, []);

  const timerCallback = () => {
    setSeconds(0);
    getBboxVerification();
  };

  useEffect(() => {
    let img = new Image();
    let finalData = [];
    img.src = imgData.image_url;
    img.onload = function() {
      setImageDimensions({
        width: this.width,
        height: this.height,
      });
      for (let [
        index,
        polygon,
      ] of imgData.finalized_classification_bboxes.entries()) {
        let newPoints = [];
        for (let [key, points] of polygon.entries()) {
          if (key === 0 || key % 2 === 0) {
            newPoints.push(points * this.height);
          } else {
            newPoints.push(points * this.width);
          }
        }

        let shape = {
          id: index,
          isClosed: true,
          color: randomColor(),
          points: [newPoints[1], newPoints[0], newPoints[3], newPoints[2]],
          hover: false,
        };

        finalData.push(shape);
      }
      setShapes([...finalData]);
    };
  }, [imgData]);

  const sendData = () => {
    let resBody = { ...imgData };

    let finalData = [];
    shapes.forEach((shape, key) => {
      let points = [];
      shape.points.forEach((point, index) => {
        if (index === 0 || index % 2 === 0) {
          points.push(point / imageDimensions.width);
        } else {
          points.push(point / imageDimensions.height);
        }
      });
      finalData.push([points[1], points[0], points[3], points[2]]);
    });
    resBody.finalized_classification_bboxes = finalData;
    if (role === "expert" && currentMode === "ordinary") {
      resBody.qa_level = currentMode;
    }
    setLoading(true);
    updateBboxMarking(resBody).then((res) => {
      if (res.error) {
        alert(res.message);
      }
      setSeconds(0);
      getBboxVerification();
    });
  };

  const skip = () => {
    let role = localStorage.getItem("role");
    let resBody = { ...imgData };
    if (role === "ordinary") {
      resBody.escalate = true;
    } else {
      resBody.skip = true;
      resBody.qa_level = currentMode;
    }
    setLoading(true);
    updateBboxMarking(resBody).then((res) => {
      if (res.error) {
        alert(res.message);
      }
      setSeconds(0);
      getBboxVerification();
    });
  };

  return (
    <div>
      {loading && <Loader />}
      <Header />
      <div style={{ padding: "20px" }} className="user-details">
        <p>
          <strong>Added By :</strong> {imgData.user}
        </p>
        <p>
          <strong>Audit ID :</strong> {imgData.audit_id}
        </p>
        <p>
          <strong>Store Name:</strong> {imgData.store_name}
        </p>
        <p>
          <strong>Manufacturer Name:</strong> {imgData.client_name}
        </p>
        <p>
          <strong>Image Index:</strong> {imgData.image_index}
        </p>
        <p>
          <strong>Date Added:</strong> {imgData.date}
        </p>
        <p>
          <strong>Bay number:</strong> {imgData.bay_number}
        </p>
        <p>
          <strong>Bay type:</strong> {imgData.bay_type}
        </p>
        {seconds > 0 && (
          <p>
            <strong>Time Remaining:</strong>{" "}
            <Timer seconds={seconds} callback={timerCallback} />
          </p>
        )}
      </div>
      {!err && (
        <div className="marking_box_container">
          <div className="marking_box">
            {shapes && (
              <Rect
                img_url={imgData.image_url}
                shapes={shapes}
                setShapes={setShapes}
              />
            )}
          </div>
          <div className="verification_actions">
            <button className="success" onClick={sendData}>
              Done
            </button>
            <button className="error" onClick={skip}>
              Skip
            </button>
            <p>
              Total Pending Images : <span>{imgData.pending || 0}</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bbox;
